<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <router-link to="/home" class="back-button">
            <ion-icon slot="icon-only" color="tetranary" :icon="chevronBackOutline"></ion-icon>
          </router-link>
        </ion-buttons>

        <ion-title color="primary">
          <h2 class="mb-0 ion-text-center">Login</h2>
        </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :scroll-events="true">
      <div id="container">
        <form action="" @submit.prevent="login" class="max-w-md mx-auto">
          <ion-grid>
            <ion-row class="ion-justify-content-center ion-align-items-center">
              <ion-col align-self-center>
                <ion-item class="ion-margin-top" lines="none">
                  <ion-label position="stacked">Email</ion-label>
                  <ion-input v-model="username" @keyup="shouldSubmit"></ion-input>
                </ion-item>
                <ion-item class="ion-margin-top" lines="none">
                  <ion-label position="stacked">Password</ion-label>
                  <ion-input type="password" v-model="password" @keyup="shouldSubmit"></ion-input>
                </ion-item>

                <div class="ion-margin-top">
                  <ion-button type="submit" expand="block" size="large" color="tertiary">
                    Login
                    <!-- <ion-ripple-effect></ion-ripple-effect> -->
                  </ion-button>
                </div>

                <div class="ion-padding ion-text-center">
                  <router-link to="/password-request">Forgot your password?</router-link>
                </div>

                <div class="ion-text-center ion-margin-top">
                  Don't have an account? <router-link to="/register">Register here</router-link>.
                </div>

                <transition name="fade" mode="out-in">
                  <div
                    v-if="0 && error"
                    class="absolute text-red-darker ml-24 py-6"
                    v-html="error"
                  ></div>
                </transition>
              </ion-col>
            </ion-row>
          </ion-grid>
        </form>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { chevronBackOutline } from 'ionicons/icons';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import {
  IonPage,
  IonHeader,
  IonIcon,
  IonToolbar,
  IonTitle,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  toastController
} from '@ionic/vue';
import { reactive, toRefs } from 'vue';
import axios from '@/axios';

export default {
  name: 'Page',
  components: {
    IonPage,
    IonIcon,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
    IonLabel,
    IonInput,
    IonButton
  },
  setup(): any {
    const store = useStore();
    const route = useRouter();

    const user = reactive({
      username: '',
      password: ''
    });

    const state = reactive({
      formSubmitted: false,
      error: ''
    });

    const APIlogin = function() {
      return axios.post('wp-json/jwt-auth/v1/token', user);
    };

    const loginFailed = async function(err) {
      state.formSubmitted = false;
      const message = 'There was a problem with the login process. Please try again later.';
      state.error = err.response?.data?.message || message;

      if (state.error.includes('<a')) {
        state.error = `Email and password did not match.`;
      }

      const toast = await toastController.create({
        color: 'danger',
        message: state.error,
        duration: 5000,
        keyboardClose: true
      });

      await toast.present();
    };

    const loginSuccessful = function(res) {
      if (res.status !== 200 || !res.data?.token) {
        loginFailed(res);
      }

      // store details in localStorage
      store.dispatch('auth/login', res.data.token);
      // get user data
      store.dispatch('user/getDataAPI');
      route.replace('/profile');
    };

    const login = async function() {
      await APIlogin()
        .then(loginSuccessful)
        .catch(loginFailed);
    };

    const shouldSubmit = function(e) {
      if (e?.keyCode === 13) {
        login();
      }
    };

    return {
      ...toRefs(user),
      ...toRefs(state),
      login,
      shouldSubmit,
      chevronBackOutline
    };
  }
};
</script>
