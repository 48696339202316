
import { chevronBackOutline } from 'ionicons/icons';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import {
  IonPage,
  IonHeader,
  IonIcon,
  IonToolbar,
  IonTitle,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  toastController
} from '@ionic/vue';
import { reactive, toRefs } from 'vue';
import axios from '@/axios';

export default {
  name: 'Page',
  components: {
    IonPage,
    IonIcon,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
    IonLabel,
    IonInput,
    IonButton
  },
  setup(): any {
    const store = useStore();
    const route = useRouter();

    const user = reactive({
      username: '',
      password: ''
    });

    const state = reactive({
      formSubmitted: false,
      error: ''
    });

    const APIlogin = function() {
      return axios.post('wp-json/jwt-auth/v1/token', user);
    };

    const loginFailed = async function(err) {
      state.formSubmitted = false;
      const message = 'There was a problem with the login process. Please try again later.';
      state.error = err.response?.data?.message || message;

      if (state.error.includes('<a')) {
        state.error = `Email and password did not match.`;
      }

      const toast = await toastController.create({
        color: 'danger',
        message: state.error,
        duration: 5000,
        keyboardClose: true
      });

      await toast.present();
    };

    const loginSuccessful = function(res) {
      if (res.status !== 200 || !res.data?.token) {
        loginFailed(res);
      }

      // store details in localStorage
      store.dispatch('auth/login', res.data.token);
      // get user data
      store.dispatch('user/getDataAPI');
      route.replace('/profile');
    };

    const login = async function() {
      await APIlogin()
        .then(loginSuccessful)
        .catch(loginFailed);
    };

    const shouldSubmit = function(e) {
      if (e?.keyCode === 13) {
        login();
      }
    };

    return {
      ...toRefs(user),
      ...toRefs(state),
      login,
      shouldSubmit,
      chevronBackOutline
    };
  }
};
